<script setup lang="ts">
import type { VideoData } from 'types/blocks';

const props = defineProps<{
  videoData: VideoData;
}>();

const videoPlayer = shallowRef<HTMLVideoElement | null>();
const hasStarted = shallowRef(false);

const { playing, muted } = useMediaControls(videoPlayer);

const videoUrl = computed(() => {
  if (props.videoData.platform === 'Youtube') {
    return `https://www.youtube-nocookie.com/embed/${props.videoData.id}?feature=oembed&amp;mute=1&amp;controls={controls}&amp;showinfo=0&amp;iv_load_policy=3&amp;autohide=1&amp;disablekb=1&amp;background=1&amp;modestbranding=1&amp;rel=0&amp;loop=0&amp;autoplay=${props.videoData.autoplay}&amp;playlist=${props.videoData.id}`;
  }

  if (props.videoData.platform === 'Vimeo') {
    return `https://player.vimeo.com/video/${props.videoData.id}?autoplay=${props.videoData.autoplay}&color=ffffff&muted=1&dnt=true`;
  }

  return '';
});

const startVideo = () => {
  hasStarted.value = true;
  playing.value = true;
  muted.value = false;
};

const { stop } = useIntersectionObserver(videoPlayer, ([{ isIntersecting }]) => {
  if (isIntersecting && props.videoData.autoplay) {
    playing.value = true;
    stop();
  }
});

onMounted(() => {
  if (props.videoData.autoplay) {
    hasStarted.value = true;
    muted.value = true;
  }
});
</script>

<template>
  <div
    v-if="videoData.video && videoData.video.url"
    class="relative"
  >
    <video
      ref="videoPlayer"
      :autoplay="videoData.autoplay"
      :muted="videoData.autoplay"
      onloadstart="this.volume=0.5"
      class="h-full w-full cursor-pointer object-cover md:h-auto"
      :controls="true"
    >
      <source
        :src="useCdnBase(videoData.video.url, true)"
        type="video/mp4"
      />
    </video>

    <Transition name="fade">
      <div
        v-if="!hasStarted && videoData.image?.url"
        class="absolute inset-0 h-full w-full cursor-pointer overflow-clip bg-black/75"
        @click="startVideo()"
      >
        <BaseImage
          v-if="videoData.image?.url"
          class="h-full w-full object-cover transition-transform duration-300 ease-out hover:scale-105"
          :src="useCdnBase(videoData.image.url)"
          :alt="videoData.image?.additionalProperties?.alt"
          sizes="xs:240 sm:360 md:420 lg:480 xl:520 xxl:800"
          format="webp"
          loading="lazy"
        />

        <div
          class="border-1 pointer-events-none absolute left-2/4 top-2/4 z-20 -translate-x-1/2 -translate-y-1/2 cursor-pointer rounded-full border-4 border-white p-6"
        >
          <BaseIcon
            class="bg-white text-white"
            name="play"
          />
        </div>
      </div>
    </Transition>
  </div>

  <div
    v-if="!videoData.video && videoData.id && videoData.platform !== ''"
    class="responsive-iframe-container"
  >
    <iframe
      class="responsive-iframe"
      frameborder="0"
      allow="autoplay; fullscreen; picture-in-picture"
      allowfullscreen
      playsinline
      :src="videoUrl"
      loading="lazy"
    ></iframe>
  </div>

  <div
    v-if="!videoData.video && (!videoData.id || videoData.platform === '')"
    class="relative"
  >
    <BaseImage
      :src="videoData.image && useCdnBase(videoData.image.url)"
      :alt="videoData.image.additionalProperties.alt"
    />
    <p
      class="text-4xl text-red-600 absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 bg-black bg-opacity-70 p-4"
    >
      {{ $t('General.NoVideoFound') }}
    </p>
  </div>
</template>

<style lang="postcss" scoped>
/* Could've implemented these with Tailwind, but I feel this better shows what is it and why.*/
.responsive-iframe-container {
  position: relative;
  overflow: hidden;
  width: 100%;
  padding-top: 56.25%; /* 16:9 Aspect Ratio (divide 9 by 16 = 0.5625) */
}

.responsive-iframe {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
}
</style>
